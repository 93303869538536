<template>
  <div v-loading="!dataLoaded" style="margin: 10px; width: calc(100vw - 85px); height: 50vh">
    <el-dialog :close-on-click-modal="false" :visible.sync="isOpenGroupForm" :title="isNewGroup ? $t('settings.group_add') : $t('settings.group_edit')" top="1vh">
      <el-form>
        <el-form-item :label="$t('settings.group_form_name')">
          <el-input v-model="groupName" />
        </el-form-item>
        <el-tabs v-if="!isNewGroup" stretch>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-car"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedDevices"
                :filter-method="filteredDevices"
                filterable
                :filter-placeholder="$t('settings.search')"
                :titles="[$t('settings.vehicles'), $t('settings.transfer_selected')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="tDevices"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-user-friends"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedUsers"
                filterable
                :filter-placeholder="$t('settings.search')"
                :titles="[$t('settings.users'), $t('settings.transfer_selected')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="users"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-address-card"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedDrivers"
                filterable
                :filter-placeholder="$t('settings.search')"
                :titles="[$t('settings.drivers'), $t('settings.transfer_selected')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="drivers"
                :render-content="renderFunc"
              >
              </el-transfer>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-map-marked"></i>
            </span>
            <el-form-item>
              <el-transfer
                v-model="selectedGeofences"
                filterable
                :filter-placeholder="$t('report.selector_search')"
                :titles="[$t('settings.zone'), $t('report.select_groups')]"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="sortedGeofences"
                :render-content="renderGeofenceFunc"
              >
              </el-transfer>
            </el-form-item>
            <div class="form-item-block">
              <div class="form-item-row">
                <el-form-item class="form-item-block-left" :label="$t('settings.geofence_form_color')">
                  <el-color-picker v-model="groupColor" :value="groupColor"></el-color-picker>
                  <br>{{ $t('Imagen') }}
                  <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="uploadSuccess"
                  >
                    <img v-if="selectedGroup.attributes.groupImg" :src="imgSrc" class="avatar" alt="">
                    <div v-if="!selectedGroup.attributes.groupImg" style="padding: 20px">
                      <el-button size="mini" type="text" plain>{{ $t('Clic para subir archivo') }}</el-button>
                    </div>
                    <span v-else class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click.stop="() => {selectedGroup.attributes.groupImg = null}"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </el-upload>
                  <el-button v-loading="loadingDeleteGeofences" type="danger" @click="deleteAllGeofences">{{ $t('Eliminar geocercas') }}</el-button>
                </el-form-item>
                <el-form-item :label="$t('Icon')" class="form-item-block-right">
                  <el-row>
                    <el-col v-for="type in markerTypes" :key="type" :span="3">
                      <el-tooltip :content="$t('settings.geofence_icon_'+type.replace('-', '_'))" placement="top">
                        <el-button
                          size="mini"
                          style="border-style: none"
                          :type="groupIcon===type?'primary':''"
                          @click="groupIcon=type"
                        >
                          <img :id="type" :src="getImageSrc(type)" alt="">
                        </el-button>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </el-form-item>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label">
              <i class="fas fa-grip-horizontal"></i>
            </span>
            <el-form-item :label="$t('Associated Group')">
              <el-select
                v-model="selectedParentGroupId"
                style="float: left; width: 70%; height: 35px"
                collapse-tags
                :placeholder="$t('settings.vehicle_form_groups_placeholder')"
                value=""
                clearable
              >
                <el-option v-for="item in filteredSubGroups" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="handleCancelGroupForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          size="small"
          @click="handleSubmitGroupForm"
        >{{ $t('settings.form_save') }}</el-button>
      </span>
    </el-dialog>
    <el-table
      v-if="dataLoaded"
      :key="groupsTableKey"
      height="calc(100vh - 100px)"
      :data="filteredGroups"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column
        :label="$t('settings.group_name')"
        prop="name"
        sortable
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.vehicle_group')"
        align="center"
        :formatter="subGroupRenderer"
        prop="groupId"
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.vehicles')"
        align="center"
        :formatter="totalVehiclesRederer"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.users')"
        align="center"
        prop="id"
      >
        <template slot-scope="scope">
          {{ scope.row.users ? scope.row.users.length : 0 }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.drivers')"
        align="center"
        prop="id"
      >
        <template slot-scope="scope">
          {{ scope.row.drivers && scope.row.drivers.length }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.zone')"
        align="center"
        prop="geofences"
        min-width="130px"
      >
        <template v-if="scope.row.geofences" slot-scope="scope">
          {{ scope.row.geofences.geofences && scope.row.geofences.geofences.length }}<i class="fas fa-draw-polygon" style="padding-left: 5px; padding-right: 25px"></i>
          {{ scope.row.geofences.pois && scope.row.geofences.pois.length }}<i class="fas fa-map-marker-alt" style="padding-left: 5px; padding-right: 25px"></i>
          {{ scope.row.geofences.linegeofences && scope.row.geofences.linegeofences.length }}<i class="fas fa-wave-square" style="padding-left: 5px; padding-right: 25px"></i>
        </template>
      </el-table-column>
      <el-table-column label="" min-width="110px">
        <template slot="header">
          <div style="float: right">
            <el-tooltip :content="$t('settings.add')" placement="top">
              <el-button
                class="tableButton"
                size="small"
                @click="handleAddGroup"
              ><i class="fas fa-plus"></i></el-button>
            </el-tooltip>
            <el-button size="mini" :loading="downloadLoading" icon="el-icon-document" type="primary" class="tableButton" @click="handleDownload">Excel</el-button>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="tableButton"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="small"
              class="tableButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="groups.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { vm } from '@/main'
import { traccar } from '@/api/traccar-api'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { pinmeApiBaseUrl } from '@/api/pinme'
import { cdnPoiImages } from '@/utils/consts'
import { pinmeapi } from '@/api/pinme'

export default {
  name: 'Groups',
  data() {
    return {
      downloadLoading: false,
      nocache: new Date().getTime(),
      cdnPoiImages: cdnPoiImages,
      groupsTableKey: 0,
      isOpenGroupForm: false,
      isNewGroup: true,
      selectedGroup: null,
      selectedDevices: [],
      selectedDrivers: [],
      selectedGeofences: [],
      selectedUsers: [],
      selectedParentGroupId: 0,
      groupColor: '',
      groupIcon: '',
      groupName: '',
      loading: false,
      loadingDeleteGeofences: false,
      filteredDevices(query, item) {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || (item.license_plate && item.license_plate.toLowerCase().indexOf(query.toLowerCase()) > -1)
      }
    }
  },
  computed: {
    ...mapGetters(['dataLoaded', 'geofences', 'drivers', 'users', 'search', 'markerTypes']),
    imgSrc() {
      return `${cdnPoiImages}/image${this.imageId}?nocache=${this.nocache}`
    },
    action() {
      return `https://api.pinme.io/pinmeapi/image/${this.imageId}?nocache=${this.nocache}`
    },
    imageId() {
      return this.selectedGroup.id
    },
    filteredGroups() {
      return this.groups.filter(data => !this.search ||
        data.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    filteredSubGroups() {
      return this.groups.filter(g => g.id !== this.selectedGroup.id)
    },
    selectedGroupDevices: function() {
      return this.devices.filter(d => d.groupId === this.selectedGroup.id)
    },
    tDevices: function() {
      return this.devices.map(d => {
        return { id: d.id, name: d.name, license_plate: d.attributes.license_plate }
      })
    },
    devices: function() {
      return vm.$store.getters.devices
    },
    areaGeofences: function() {
      return this.geofences.filter(g => g.area.startsWith('POLYGON'))
    },
    pois: function() {
      return this.geofences.filter(g => g.area.startsWith('CIRCLE')).sort((a, b) => a.name.localeCompare(b.name))
    },
    lineGeofences: function() {
      return this.geofences.filter(g => g.area.startsWith('LINESTRING'))
    },
    groups() {
      return vm.$store.getters.groups.sort((a, b) => (a.name > b.name) ? 1 : -1)
    },
    sortedGeofences() {
      return vm.$store.getters.geofences.sort((a, b) => (a.name > b.name) ? 1 : -1)
    }
  },
  methods: {
    async deleteAllGeofences() {
      await this.$confirm(this.$t('Delete all zones?'), { type: 'warning' })
      this.loadingDeleteGeofences = true
      try {
        await pinmeapi.deleteGeofencesFromGroup(this.selectedGroup.id)
        this.$message('OK!', { type: 'success' })
      } catch (e) {
        await this.$alert(e.message)
      }
      this.loadingDeleteGeofences = false
    },
    uploadSuccess() {
      this.nocache = new Date().getTime()
      this.selectedGroup.attributes.groupImg = this.imgSrc
    },
    pinmeApiBaseUrl() {
      return pinmeApiBaseUrl
    },
    getImageSrc(imgType) {
      return './img/icons/pois/' + imgType + '-blue.svg'
    },
    handleCancelGroupForm() {
      this.isOpenGroupForm = false
      this.clearFormData()
    },
    async handleSubmitGroupForm() {
      this.loading = true
      if (this.isNewGroup) {
        this.submitNewGroup()
      } else {
        try {
          const groupData = {
            id: this.selectedGroup.id,
            attributes: {
              groupIcon: this.groupIcon,
              groupColor: this.groupColor,
              groupImg: this.selectedGroup.attributes.groupImg
            },
            groupId: this.selectedParentGroupId,
            name: this.groupName
          }
          await this.updateGroupPermissions()
          await traccar.editGroup(this.selectedGroup.id, groupData)
          groupData.drivers = this.selectedDrivers
          groupData.users = this.users.filter(u => this.selectedUsers.includes(u.id))
          groupData.geofences = {
            geofences: this.geofences.filter(g => this.selectedGeofences.includes(g.id) && g.area.startsWith('POLYGON')).map(g => g.id),
            pois: this.geofences.filter(g => this.selectedGeofences.includes(g.id) && g.area.startsWith('CIRCLE')).map(g => g.id),
            linegeofences: this.geofences.filter(g => this.selectedGeofences.includes(g.id) && g.area.startsWith('LINESTRING')).map(g => g.id)
          }
          this.$store.commit('user/UPDATE_GROUP', { oldGroup: this.selectedGroup, newGroup: groupData })
          this.groupUpdated()
          this.isOpenGroupForm = false
        } catch (reason) {
          if (reason.response && (reason.response.data.startsWith('Manager access required') ||
            reason.response.data.startsWith('Account is readonly') ||
            reason.response.data.startsWith('Account is device readonly'))) {
            this.$message({
              message: this.$t('settings.group_edit_not_allowed'),
              type: 'warning',
              duration: 5 * 1000
            })
          } else {
            Vue.$log.error(reason)
            if (reason.response && reason.response.data) {
              await this.$alert(reason.response.data)
            } else {
              await this.$alert(reason)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    async updateGroupPermissions() {
      const self = this

      const currentGroupDevices = this.groupDevices()
      const devicesToRemove = currentGroupDevices.filter(x => !this.selectedDevices.includes(x))
      const devicesToAdd = this.selectedDevices.filter(x => !currentGroupDevices.includes(x))

      for (const id of devicesToRemove) {
        await this.updateDeviceGroup(id, null)
      }

      for (const id of devicesToAdd) {
        await this.updateDeviceGroup(id, this.selectedGroup.id)
      }

      const driversToRemove = this.selectedGroup.drivers.filter(id => !self.selectedDrivers.includes(id))
      const driversToAdd = this.selectedDrivers.filter(id => !self.selectedGroup.drivers.includes(id))

      const driverPermissionsToRemove = driversToRemove.map(id => {
        return {
          groupId: self.selectedGroup.id,
          driverId: id
        }
      })
      const driverPermissionsToAdd = driversToAdd.map(id => {
        return {
          groupId: self.selectedGroup.id,
          driverId: id
        }
      })

      await traccar.deleteAllPermissions(driverPermissionsToRemove)
      await traccar.addAllPermissions(driverPermissionsToAdd)

      const allGeofences = self.selectedGeofences
      const allOriginalGeofences = self.selectedGroup.geofences ? self.selectedGroup.geofences.geofences.concat(self.selectedGroup.geofences.pois)
        .concat(self.selectedGroup.geofences.linegeofences) : []

      const geofencesToRemove = allOriginalGeofences.filter(x => !allGeofences.includes(x))
      const geofencesToAdd = allGeofences.filter(x => !allOriginalGeofences.includes(x))

      const groupGeofencePermissionsToRemove = geofencesToRemove.map(g => {
        return {
          groupId: self.selectedGroup.id,
          geofenceId: g
        }
      })
      const groupGeofencePermissionsToAdd = geofencesToAdd.map(g => {
        return {
          groupId: self.selectedGroup.id,
          geofenceId: g
        }
      })

      await traccar.deleteAllPermissions(groupGeofencePermissionsToRemove)
      // https://www.traccar.org/forums/topic/geofences-in-groups-should-be-visible-to-users-directly-without-the-need-for-user-geofence-association/
      await pinmeapi.addAllPermissions(groupGeofencePermissionsToAdd)

      const usersToRemove = this.selectedGroup.users.filter(x => !self.selectedUsers.includes(x.id))
      const userIds = self.selectedGroup.users.map(u => u.id)
      const usersToAdd = this.selectedUsers.filter(x => !userIds.includes(x))

      const userPermissionsToRemove = usersToRemove.map(d => {
        return {
          userId: d.id,
          groupId: self.selectedGroup.id

        }
      })
      const userPermissionsToAdd = usersToAdd.map(uId => {
        return {
          userId: uId,
          groupId: self.selectedGroup.id
        }
      })
      const usersToAddGeofencePermissionsToAdd = allGeofences.map(g => {
        return usersToAdd.map(u => {
          return {
            userId: u,
            geofenceId: g
          }
        })
      }).flat()

      await traccar.deleteAllPermissions(userPermissionsToRemove)
      await traccar.addAllPermissions(userPermissionsToAdd)
      await traccar.addAllPermissions(usersToAddGeofencePermissionsToAdd)
    },
    async updateDeviceGroup(id, value) {
      const vehicle = this.devices.find(d => d.id === id)
      vehicle.groupId = value
      await traccar.updateDevice(vehicle.id, vehicle)
    },
    submitNewGroup: function() {
      const newGroup = {
        name: this.groupName
      }
      traccar.newGroup(newGroup)
        .then(response => this.groupCreated(response.data))
        .catch(reason => {
          if (reason.response.data.startsWith('Account is readonly')) {
            this.$message({
              message: this.$t('settings.group_add_not_allowed'),
              type: 'warning',
              duration: 5 * 1000
            })
          } else {
            Vue.$log.error(reason)
            this.$alert(reason)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    groupCreated: function(newGroup) {
      newGroup.drivers = []
      newGroup.users = []
      newGroup.geofences = {
        geofences: [],
        pois: [],
        linegeofences: []
      }
      this.$message({
        type: 'success',
        message: this.$t('settings.group_created')
      })

      this.isOpenGroupForm = false
      this.clearFormData()
      vm.$store.state.user.groups.push(newGroup)
    },
    groupUpdated: function() {
      this.$message({
        type: 'success',
        message: this.$t('settings.group_updated')
      })
      this.clearFormData()
    },
    handleAddGroup() {
      this.isNewGroup = true
      this.isOpenGroupForm = !this.isOpenAlertForm
    },
    async handleEdit(row) {
      this.isNewGroup = false
      this.selectedGroup = row
      this.selectedDevices = this.groupDevices()
      this.selectedDrivers = row.drivers
      this.selectedGeofences = row.geofences ? row.geofences.geofences.concat(row.geofences.pois)
        .concat(row.geofences.linegeofences) : []
      this.selectedUsers = row.users ? row.users.map(u => u.id) : []
      this.selectedParentGroupId = row.groupId
      this.groupName = row.name
      this.groupIcon = row.attributes.groupIcon
      this.groupColor = row.attributes.groupColor
      this.isOpenGroupForm = !this.isOpenGroupForm
    },
    groupDevices() {
      return this.devices.filter(d => d.groupId === this.selectedGroup.id).map(d => d.id)
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    subGroupRenderer(row, column, cellValue) {
      if (cellValue) {
        const group = this.groups.find(d => d.id === cellValue)
        return (group && group.name) || cellValue
      } else {
        return ''
      }
    },
    totalVehiclesRederer(row, column, cellValue) {
      if (cellValue) {
        return vm.$store.getters.devices.filter(d => d.groupId === cellValue).length
      } else {
        return ''
      }
    },
    handleDelete(row) {
      this.$confirm(this.$t('settings.group_delete_info') + row.name, this.$t('settings.group_delete_title'), {
        confirmButtonText: this.$t('settings.form_confirm'),
        cancelButtonText: this.$t('settings.form_cancel')
      }).then(() => {
        traccar.deleteGroup(row.id)
          .then(() => this.groupDeleted(row.id))
          .catch(reason => {
            Vue.$log.debug(reason)
            if (reason.response.data.startsWith('Account is readonly')) {
              this.$message({
                message: this.$t('settings.group_delete_not_allowed'),
                type: 'warning',
                duration: 5 * 1000
              })
            } else {
              Vue.$log.error(reason)
              this.$alert(reason)
            }
          })
      }).catch(() => {
      })
    },
    groupDeleted(id) {
      this.$log.debug('group deleted')
      this.$message({
        message: this.$t('settings.group_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
      const groupDeleted = vm.$store.state.user.groups.find(g => g.id === id)
      vm.$store.state.user.groups.splice(vm.$store.state.user.groups.indexOf(groupDeleted), 1)
    },
    clearFormData() {
      this.groupName = ''
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    },
    renderGeofenceFunc(h, option) {
      const data = option.area.startsWith('POLYGON') ? 'fas fa-draw-polygon' : (option.area.startsWith('CIRCLE') ? 'fas fa-map-marker-alt' : 'fas fa-wave-square')
      return <div><i class={data}></i> <span title={option.name}>{option.name}</span></div>
    },
    handleDownload() {
      this.downloadLoading = true
      import('../../../utils/ExportExcel').then(excel => {
        const tHeader = [this.$t('settings.groups'), this.$t('settings.users')]
        const data = []
        this.groups.forEach(g => {
          g.users.forEach(u => {
            data.push([g.name, u.name])
          })
        })
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
       for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>

<style lang="scss">
  @import '../../../styles/element-variables.scss';
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .tableButton {
    float: right;
    margin-right: 10px;
  }

  .el-pagination__total{
    color: $--color-primary
  }

  .el-table .tomobile td:last-child {
    font-size: 12px
  }

  .el-form-item {
    margin-bottom: 5px
  }
  .form-item-block-left{
    display: table-cell;
    width: 30px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .form-item-block-right{
    width: 450px;
    display: table-cell;
    padding-bottom: 5px;
  }
  .alertSelectButton {
    float: left;
    margin-left: 10px;
    height: 40px
  }
</style>
